<template>
  <div class="assinatura">
    <Voltar />
    <h1 class="titulo">Política de Privacidade</h1>
    <embed
        :src="state.termo.politicaPrivacidade"
        width="100%" height="600"
        type="application/pdf">
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import services from '../../services'
import Voltar from '../../components/Voltar'
export default {
  setup () {
    const router = useRouter()
    const toast = useToast()

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      termo: {}
    })

    async function getTermo () {
      try {
        state.isLoading = true
        const { data, errors } = await services.termo.index()

        if (!errors) {
          state.termo = data
          state.isLoading = false
          return
        }

        if (errors.status === 404 || errors.status === 401) {
          toast.error('Política de Privacidade não encontrada')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer carregar a Política de Privacidade!')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getTermo()

    return {
      router,
      state
    }
  },
  components: { Voltar }
}
</script>
